<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/devias.png')"
              max-height="500px"
              max-width="250px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            ¡Bienvenido a Devias logistic!
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text class="containerNoDetailsInput">
          <v-form>
            <v-text-field
              v-model="inputs.email.value"
              :error="inputs.email.errors.length > 0"
              :messages="inputs.email.errors"
              outlined
              label="Correo"
              placeholder="usuario@ejemplo.com"
              class="mb-3"
              v-on:keyup.enter="login"
            ></v-text-field>

            <v-text-field
              v-model="inputs.password.value"
              :error="inputs.password.errors.length > 0"
              :messages="inputs.password.errors"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Contraseña"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
              v-on:keyup.enter="login"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <div></div>

              <!-- forgot link -->
              <a href="/recuperar-contrasena" class="mt-1">
                ¿Olvidaste la contraseña?
              </a>
            </div>

            <v-btn block color="primary" class="mt-6" @click="login" :loading="loadingForm">
              Iniciar sesion
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2"> </v-card-text>

        <!-- divider -->
        <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">Encuetranos en</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="500"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="500" height="300" src="@/assets/images/misc/camion-repartidor.svg"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="190" height="300" src="@/assets/images/misc/hombre.svg"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import helpers from '../../mixins/helpers'

export default {
  mixins: [helpers],
  data() {
    return {
      isPasswordVisible: false,
      inputs: {
        email: {
          value: null,
          errors: [],
        },
        password: {
          value: null,
          errors: [],
        },
      },
      socialLink: [
        {
          icon: mdiFacebook,
          color: '#4267b2',
          colorInDark: '#4267b2',
        },
        {
          icon: mdiTwitter,
          color: '#1da1f2',
          colorInDark: '#1da1f2',
        },
        {
          icon: mdiGithub,
          color: '#272727',
          colorInDark: '#fff',
        },
        {
          icon: mdiGoogle,
          color: '#db4437',
          colorInDark: '#db4437',
        },
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      loadingForm: false,
    }
  },
  mounted() {

    this.$store.dispatch("getUser").then(() => {
      if (this.$store.getters.getUser !== null) {
        this.$router.push({ name: "panelInicio" });
      }
    });
  },
  methods: {
    login() {
      this.resetErrors()
      this.trimInputsText()
      this.loadingForm = true;

      this.$http
        .get(this.$base_api_sanctum_url + '/sanctum/csrf-cookie')
        .then(() => {
          let data = {
            email: this.inputs.email.value,
            password: this.inputs.password.value,
          }

          this.$http
            .post(this.$base_api_url + '/auth', data)
            .then(() => {
              this.$store.dispatch('getUser').then(() => {
                this.$router.push({ name: 'panelInicio' })
              })
            })
            .catch(e => {
              this.catchResponse(e)
            }).then(() => {
              this.loadingForm = false;
            })
        })
        .catch(e => {
          this.catchResponse(e)
          this.loadingForm = false;
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
